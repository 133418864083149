<template>
  <div>
    <!-- FILTERS -->
    <TopupListFilters
      v-if="statusTopupOptions"
      :startDateFilter.sync="startDateFilter"
      :endDateFilter.sync="endDateFilter"
      :paymentStatusFilter.sync="paymentStatusFilter"
      :search-keywords.sync="searchKeywords"
      :status-topup-options="statusTopupOptions.filter(item => ['PENDING_PAYMENT', 'DONE'].includes(item.value))"
      :paymentStatusByRoute="paymentStatusByRoute"
      @refetchData="refetchData"
      @clearFilter="clearFilter"
    />
    <b-card no-body>
      <!-- TABLE HEADER -->
      <div class="px-2 py-1">
        <b-row>
          <!-- PER PAGE -->
          <b-col
            cols="4"
            md="auto"
            class="px-0 mr-md-1"
          >
            <v-select
              v-model="sizePerPage"
              :options="sizePerPageLgOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template #option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>
            </v-select>
          </b-col>

          <!-- TOPUP BUTTON & EXPORT BUTTON -->
          <b-col
            cols="8"
            md="auto"
            class="d-flex justify-content-end px-50"
            order-md="3"
          >
            <div class="d-none d-lg-flex gap-2">
              <!-- ANCHOR: CREATE TOPUP lên F cha -->
              <div id="button-create-topup-modal">
                <b-button
                  v-if="(roleF2 || roleF3) && entityTypeByRoute === 'SENDER'"
                  :disabled="!allowTopup"
                  variant="warning"
                  class="min-width-120"
                  @click="handleShowModalCreateTopup(false)"
                >
                  <span class="text-nowrap d-flex-center gap-1">
                    <feather-icon
                      icon="DollarSignIcon"
                      size="16"
                    />
                    {{ $t('topup.createTopup') }}
                    <!-- {{ roleF2 ? 'F1' : roleF3 ? '' : '' }} -->
                  </span>
                </b-button>
                <b-tooltip
                  v-if="!allowTopup"
                  boundary="window"
                  variant="warning"
                  target="button-create-topup-modal"
                >
                  <span class="text-subtitle text-white">{{ $t('topup.tooltip.allowTopup') }}</span>
                </b-tooltip>
              </div>

              <!-- ANCHOR: CREATE TOPUP cho F con -->
              <b-button
                v-if="(roleF2 || isRoleF1) && entityTypeByRoute === 'RECIPIENT'"
                variant="warning"
                class="min-width-120"
                @click="handleShowModalCreateTopup(true)"
              >
                <span class="text-nowrap d-flex-center gap-1">
                  <feather-icon
                    icon="DollarSignIcon"
                    size="16"
                  />
                  {{ $t('topup.createTopupForChild') }}
                </span>
              </b-button>

              <b-button
                variant="info"
                class="min-width-120"
                @click="confirmExport"
              >
                <span class="text-nowrap d-flex-center gap-1">
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                  />
                  {{ $t('export') }}
                </span>
              </b-button>
            </div>

            <!-- DROPDOWN -->
            <div class="d-block d-lg-none">
              <b-dropdown
                variant="info"
                boundary="window"
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="MenuIcon"
                    size="16"
                  />
                  <span class="pl-50">{{ $t('topup.moreDropdownTitle') }}</span>
                </template>
                <b-dropdown-item
                  v-if="(roleF2 || roleF3) && entityTypeByRoute === 'SENDER'"
                  :disabled="!allowTopup"
                  @click="handleShowModalCreateTopup(false)"
                >
                  <div class="d-flex align-items-center gap-1">
                    <feather-icon
                      icon="DollarSignIcon"
                      size="16"
                    />
                    {{ $t('topup.createTopup') }}
                  </div>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="(roleF2 || isRoleF1) && entityTypeByRoute === 'RECIPIENT'"
                  @click="handleShowModalCreateTopup(true)"
                >
                  <div class="d-flex align-items-center gap-1">
                    <feather-icon
                      icon="DollarSignIcon"
                      size="16"
                    />
                    {{ $t('topup.createTopupForChild') }}
                  </div>
                </b-dropdown-item>

                <b-dropdown-item @click="confirmExport">
                  <div class="d-flex align-items-center gap-1">
                    <feather-icon
                      icon="DownloadIcon"
                      size="16"
                    />
                    {{ $t('export') }}
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>

          <!-- SEARCH KEYWORDS -->
          <b-col
            cols="12"
            md="auto"
            class="mt-1 mt-md-0 px-50 flex-grow-1"
          >
            <div class="d-flex align-items-center">
              <b-input-group
                size="md"
                class="w-100 mr-md-1"
              >
                <template #prepend>
                  <div class="d-flex align-items-center border rounded-left px-1 bg-light-info">
                    <feather-icon
                      icon="SearchIcon"
                      size="20"
                    />
                  </div>
                </template>

                <b-form-input
                  v-model="searchKeywords"
                  type="search"
                  :placeholder="$t('topup.placeholderSearch')"
                  :reduce="val => val.value"
                  debounce="500"
                  trim
                />
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- TABLE CONTENT -->
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <b-table
          ref="refTopupListTable"
          style="max-height: 80vh"
          sticky-header
          responsive
          show-empty
          bordered
          primary-key="id"
          class="position-relative"
          :items="fetchTopups"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :empty-text="$t('noRecordFund')"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- COLUMNS HEADER -->
          <template
            v-for="(column) in tableColumns"
            #[`head(${column.key})`]="data"
          >
            <span
              :key="column.label"
              class="text-dark text-nowrap"
            >
              {{ $t(`topup.tableColumns.${data.label}`) }}
            </span>
          </template>

          <!-- Column: PAYMENT CODE -->
          <template #cell(paymentCode)="data">
            <b-link
              :to="{ name: 'apps-topup-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap text-info pb-0"
            >
              {{ data.item.paymentCode }}
            </b-link>
          </template>

          <!-- Column: SENDER INFO -->
          <template #cell(senderInfo)="data">
            <div>
              <span>
                ID:
              </span>
              <b-link
                :to="{ name: 'apps-agencies-edit', params: { id: data.item.senderId } }"
                class="font-weight-bold text-nowrap text-info pb-0"
              >
                {{ data.item.senderId }}
              </b-link>
            </div>

            <div>
              {{ $t('topup.senderName') }}:
              <span class="font-weight-bold">
                {{ data.item.senderName }}
              </span>
            </div>

            <div>
              {{ $t('topup.entity') }}:
              <span class="font-weight-bold">
                {{ data.item.senderEntity }}
              </span>
            </div>
          </template>

          <!-- Column: CREATED-->
          <template #cell(createdAt)="data">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-info"
                  size="34"
                >
                  <feather-icon
                    icon="ClockIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0 text-nowrap">
                  {{ getDate(data.item.createdAt) }}
                </h6>
                <small>
                  {{ getHour(data.item.createdAt) }}
                </small>
              </b-media-body>
            </b-media>
          </template>

          <!-- Column: UPDATED -->
          <template #cell(updatedAt)="data">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-info"
                  size="34"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0 text-nowrap">
                  {{ getDate(data.item.updatedAt) }}
                </h6>
                <small>
                  {{ getHour(data.item.updatedAt) }}
                </small>
              </b-media-body>
            </b-media>
          </template>

          <!-- Column: PAYMENT STATUS -->
          <template #cell(paymentStatus)="data">
            <b-badge
              :variant="resolveTopupStatusVariant(data.item.paymentStatus)"
              class="badge-glow text-uppercase"
            >
              {{ $t(resolveTopupStatus(data.item.paymentStatus)) }}
            </b-badge>
          </template>

          <template #cell(confirmedBy)="data">
            <div
              v-if="data.item.confirmedBy"
              class="font-weight-bold text-uppercase"
            >
              {{ data.item.confirmedBy?.username }}
            </div>
            <span v-if="data.item?.confirmedAt">
              {{ convertISODateTime(data.item?.confirmedAt).dateTime }}
            </span>
          </template>

          <!-- Column: PAYMENT INFO -->
          <template #cell(paymentInfo)="data">
            <div>
              {{ $t('topup.amount') }}:
              <span class="font-weight-bold text-danger">
                {{ formatCurrency(data.item.paymentAmount) }}
              </span>
              <span class="font-weight-bold">
                {{ $t('topup.currency') }}
              </span>
            </div>
            <div>
              {{ $t('topup.evidence') }}:
              <b-link
                v-if="data.item.evidenceUrl"
                :href="data.item.evidenceUrl"
                target="_blank"
              >
                Link
              </b-link>
              <span
                v-else
                class="font-weight-bold"
              >
                N/A
              </span>
            </div>
            <div>
              {{ $t('topup.note') }}:
              <span class="font-weight-bold">
                {{ data.item.note || 'N/A' }}
              </span>
            </div>
          </template>

          <!-- Column: ACTIONS -->
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <!-- <feather-icon
                :id="`topup-row-${data.item.id}-send-icon`"
                icon="SendIcon"
                class="text-warning cursor-pointer mr-1"
                size="16"
              />
              <b-tooltip
                title="Send top up to client by email"
                :target="`topup-row-${data.item.id}-send-icon`"
              /> -->

              <b-link
                :to="{ name: 'apps-topup-view', params: { id: data.item.id } }"
                class="text-info"
              >
                <feather-icon
                  :id="`topup-row-${data.item.id}-view-detail-icon`"
                  icon="FileTextIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                />
                <b-tooltip
                  boundary="window"
                  title="Xem chi tiết"
                  :target="`topup-row-${data.item.id}-view-detail-icon`"
                />
              </b-link>

              <template v-if="canConfirmManual(data.item)">
                <feather-icon
                  :id="`topup-row-${data.item.id}-confirm-manual-icon`"
                  icon="CheckCircleIcon"
                  size="16"
                  class="text-success cursor-pointer mr-1"
                  @click="onOpenPopupConfirmManual(
                    data.item.paymentCode,
                    data.item.paymentAmount,
                    data.item.type,
                  )"
                />
                <b-tooltip
                  v-if="!isMobileView"
                  boundary="window"
                  :title="$t('topup.confirmManual')"
                  :target="`topup-row-${data.item.id}-confirm-manual-icon`"
                />
              </template>

              <template v-if="canDelete(data.item)">
                <feather-icon
                  :id="`topup-row-${data.item.id}-delete-icon`"
                  icon="TrashIcon"
                  size="16"
                  class="text-danger cursor-pointer mr-1"
                  @click="confirmDelete(data.item.id)"
                />
                <b-tooltip
                  boundary="window"
                  :title="$t('topup.delete')"
                  :target="`topup-row-${data.item.id}-delete-icon`"
                />
              </template>
            </div>
          </template>
        </b-table>
      </b-overlay>

      <!-- TABLE FOOTER -->
      <div class="mx-2 mb-1">
        <b-row>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-center"
          >
            <span class="text-muted">
              {{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
              {{ dataMeta.of }} {{ $t('paginationText.items') }}
            </span>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTopups"
              :per-page="sizePerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <TopupPopupConfirmManual
      :pay-code="paymentCode"
      :pay-amount="paymentAmount"
      :pay-type="paymentType"
      @hidden="resetPaymentData"
      @refetch-data="refetchData"
    />

    <CreateTopupModal
      :for-child="topupForChild"
      :sender="senderData"
      :recipient="recipientData"
      :is-empty-bank-accounts="isEmptyBankAccounts"
      :place="placeModal"
      @show="handleOpenTopupModal"
    />
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaBody,
  BMediaAside,
  BPagination,
  BInputGroup,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, ref, computed } from '@vue/composition-api'

import router from '@/router'
import {
  SENDER_TYPES, AGENCY_STATUS_CAN_TOPUP,
  TOPUP_APP_STORE_MODULE_NAME as TOPUP_STORE,
} from '@/constants/topup'
import store from '@/store'
import {
  sizePerPageLgOptions,
  statusTopupOptions,
  resolveTopupStatus,
  resolveTopupStatusVariant,
} from '@/constants/selectOptions'

import {
  getDate, getHour, formatCurrency, convertISODateTime,
} from '@core/utils/filter'

import useTopupHandle from '../useTopupHandle'
import topupStoreModule from '../topupStoreModule'
import TopupListFilters from './TopupListFilters.vue'

export default {
  components: {
    TopupListFilters,
    BMediaBody,
    BBadge,
    BInputGroup,
    BMediaAside,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BTooltip,
    vSelect,

    TopupPopupConfirmManual: () => import('@topup/topup-popup/TopupPopupConfirmManual.vue'),
    CreateTopupModal: () => import('@topup/topup-popup/CreateTopupModal.vue'),
  },
  setup() {
    // Register module
    if (!store.hasModule(TOPUP_STORE)) store.registerModule(TOPUP_STORE, topupStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TOPUP_STORE)) store.unregisterModule(TOPUP_STORE)
    })

    const {
      tableColumns,
      refTopupListTable,
      loading,
      searchKeywords,
      totalTopups,
      sizePerPage,
      currentPage,
      sortBy,
      isSortDirDesc,
      dataMeta,

      fetchTopups,
      refetchData,
      clearFilter,
      isChecked,
      chooseItem,
      selectedIds,
      exportTopups,
      deletePopup,

      // for topup confirm manual
      paymentCode,
      paymentAmount,
      paymentType,

      canConfirmManual,
      canDelete,
      resetPaymentData,
      onOpenPopupConfirmManual,
      entityTypeByRoute,
      paymentStatusByRoute,
      startDateFilter,
      endDateFilter,
      paymentStatusFilter,
    } = useTopupHandle()

    function confirmExport() {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmExport') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            exportTopups(this.$i18n.locale)
            selectedIds.value = []
          }
        })
    }

    function confirmDelete(id) {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmDelete') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            deletePopup(id)
          }
        })
    }

    // ANCHOR Create topup
    const agencyData = computed(() => store.getters['userStore/getAgencyData'])
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const roleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const roleF3 = computed(() => store.getters['userStore/getRoleCTV'])
    const senderData = ref({})
    const recipientData = ref({})
    const allowTopup = computed(() => agencyData?.value?.status === AGENCY_STATUS_CAN_TOPUP)
    const isEmptyBankAccounts = ref(false)
    const topupForChild = ref(false)
    const setSenderData = () => {
      if (topupForChild.value) {
        senderData.value = null
      } else {
        senderData.value = {
          id: agencyData.value.id,
          agencyName: agencyData.value.agencyName,
          agencyCode: agencyData.value.agencyCode,
          type: SENDER_TYPES.AGENCY,
        }
      }
    }

    const setRecipientData = () => {
      if (topupForChild.value) {
        recipientData.value = {
          id: agencyData.value.id,
          agencyName: agencyData.value.agencyName,
          agencyCode: agencyData.value.agencyCode,
          bankAccounts: agencyData.value?.bankAccs ?? agencyData.value?.bankAccounts ?? [],
        }
        if (!(agencyData.value?.bankAccs?.length ?? agencyData.value?.bankAccounts?.length)) isEmptyBankAccounts.value = true
      } else {
        recipientData.value = {
          id: agencyData.value.parentAgency.id,
          agencyName: agencyData.value.parentAgency.agencyName,
          agencyCode: agencyData.value.parentAgency.agencyCode,
          bankAccounts: agencyData.value?.parentAgency?.bankAccs ?? agencyData.value?.parentAgency?.bankAccounts ?? [],
        }
        if (!(agencyData.value?.parentAgency?.bankAccs?.length ?? agencyData.value?.parentAgency?.bankAccounts?.length)) {
          isEmptyBankAccounts.value = true
        }
      }
    }

    const handleOpenTopupModal = () => {
      setSenderData()
      setRecipientData()
    }

    const placeModal = computed(() => router?.currentRoute?.name || '')

    function handleShowModalCreateTopup(forChild = false) {
      topupForChild.value = forChild
      this.$bvModal.show(`create-topup-modal${placeModal.value}`)
    }

    return {
      handleShowModalCreateTopup,
      placeModal,

      // from imports
      sizePerPageLgOptions,
      statusTopupOptions,

      resolveTopupStatus,
      resolveTopupStatusVariant,
      getDate,
      getHour,
      formatCurrency,

      // returned by useTopupHandle()
      tableColumns,
      refTopupListTable,
      loading,
      searchKeywords,
      totalTopups,
      sizePerPage,
      currentPage,
      sortBy,
      isSortDirDesc,
      dataMeta,
      entityTypeByRoute,

      fetchTopups,
      refetchData,
      clearFilter,
      isChecked,
      chooseItem,
      exportTopups,
      deletePopup,

      // function access Vue instance
      confirmExport,
      confirmDelete,

      // for topup confirm manual
      paymentCode,
      paymentAmount,
      paymentType,

      canConfirmManual,
      canDelete,
      resetPaymentData,
      onOpenPopupConfirmManual,

      // create topup
      handleOpenTopupModal,
      recipientData,
      senderData,
      isRoleF1,
      roleF2,
      roleF3,
      allowTopup,
      isEmptyBankAccounts,
      convertISODateTime,
      topupForChild,
      paymentStatusByRoute,
      startDateFilter,
      endDateFilter,
      paymentStatusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
